import taskCatalogSummary from './TaskCatalogSummary';

//3 Prompts
//First prompt summarizes assignment and asks any clarifying questions- this can repeat if there are clarifying questions
//Second prompt blocks (not streaming). It creates the assignment and saves it in redis under /assignments/current (& table is refreshed)
//Third Prompt summarized the list of tasks and asks the user for any feedback or revisions.
//Fourth prompt summarizes what has been done

export const getInsightPrompt1 = () => {
  const prompt = `You are an intelligent agent that will eventually assemble a lists of tasks in order to gather insights a user is interested in.
The tasks will eventually be chosen by you from the task catalog, as many as it takes to complete to gather the information and summarize insights.
The purpose now, though, is to make sure you have all the information you need from the user.
Please read over the user conversation about what interests the user, look at the task catalog, and decide if the user has provided
enough information to assemble a proper list of tasks to gather information the user might like.
If you have enough information from the user, respond with just the text: 'insight ready' with no quotes.
If you need more information, please respond to the user what additional information they should 
provide you, such as clarifications or data they should provide. 

Task catalog:
${taskCatalogSummary}

User assignment conversation (rest of discussion):

`;
  return prompt;
};

export const getInsightPrompt2 = () => {
  const prompt = `Please reply to the user in a personalized way that you appreciate their information about 
  insights they are interested in and that you will work to gather informtion over time that you
  hope they will find useful.  Perhaps mention a detail of their request in your response.
`;
  return prompt;
};


//This should be done at the API, not JS
export const getInsightPrompt3 = () => {
  const prompt = `From the previous conversation, can you create a list of 5 to 10 ideas
`;
  return prompt;
};

//This should be done at the API, not JS
export const getInsightPrompt4 = () => {
  const prompt = `You are an intelligent agent that will assemble a lists of tasks to do to complete a user's assignment.
The tasks will be chosen from the task catalog, and assembled in a valid json list.
You can use as many tasks as needed to complete the assignment.
For task parameters, please provide values needed for that step.
Your goal now is to read the user assignment conversation, and assemble the json lists of tasks with parameter values.
Only return valid json with the task list.  Do not send any extraneous text or comments about the assignment.
The final json form should look like this example that has a unique id field, consecutive step_id fields starting with 1,
a description field which is to describe this task to an end user, a status field with the value "under review",
and an operation_id field which matches the id field from the task catalog.  
Notice in the example below, if a future step needs the data from a previous step, use {{step_NUMBER}},
like {{step_1}} or {{step_2}} to indicate that data should be referenced there, such as a 
summarization prompt for an LLM.  The final step is typically a call to an LLM for 
summarization, and there is no need for an additional step to save the LLM output as it 
will be available automatically.
\nHere is an example of the format I would like
returned:\n
[
{
  "operation_id": "simple_web_search",
  "id": "1049301",
  "step_id": 1,
  "description": "Search internet for Llama LLM model slot filling prompts",
  "status": "Under review",
  "output": "Not ready",
  "parameters": {
    "search_term": "Llama LLM model slot filling prompts",
    "num_results": 5,
    "return_results": true,
    "save_to_es": false,
    "task_id": "Web_Search_Task_Llama"
  }
},
{
  "operation_id": "simple_web_search",
  "description": "Search internet for Llama2 LLM model slot filling prompts",
  "id": "1049302",
  "step_id": 2,
  "status": "Under review",
  "output": "Not ready",
  "parameters": {
    "search_term": "Llama2 LLM model slot filling prompts",
    "num_results": 5,
    "return_results": true,
    "save_to_es": false,
    "task_id": "Web_Search_Task_Llama2"
  },
  {
    "operation_id": "post_llm_simple",
    "step_id": 5,
    "description": "Use AI (LLM) to summarize the search results from step 1 and step 2",
    "id": "10523323",
    "status": "Under review",
    "output": "Not ready",
    "parameters": {
      "model": "Llama_LLM_Model_Name"
    },
    "post_hint": [
      {
        "role": "user",
        "contents": "Summarize the key findings regarding prompts for these json search results: {{step_1}} and {{step_2}}"
      }
    ]
  }
} 
...
]\n
(end of example)

Task catalog:
${taskCatalogSummary}

User assignment conversation (rest of discussion):
`;
  return prompt;
};

export const getInsightPrompt5 = completedTaskJson => {
  const prompt = `You are an intelligent agent that has just created a list of tasks to complete a user's assignment.
The goal now is to review the Proposed Task List you have created with the user so they may confirm it
or ask for adjustments.  The user has just been shown the proposed task list.  Please reply to the 
user with a summary of the proposed task list, how it should help complete the assignment, and
ask for user feedback on how you can revise it.  Let the user know if they are satisfied with the
assignment they can press the Execute button, or press the Clear button to start over.

Proposed Task List:
${completedTaskJson}

User assignment (rest of conversation):
`;

  return prompt;
};

/*
  const getInsightPrompt4 = completedTaskJson => {
    const prompt = `You are an intelligent agent that has just executed a list of tasks to complete a user's assignment.
The goal now is to look the completed task json list and reply to the user with a nice summary of what you have
done to complete the tasks and where their results are.  You may also add suggestions of what you might recommend
later as a next future assignment related to this task.  You will also want to site sources used in completing the assignment wherever you can.


Completed Task Json List:
${completedTaskJson}

User assignment (rest of conversation):
`;

    return prompt;
  };
  */
