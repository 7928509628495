const taskCatalogSummary = `[
{
    "id": "post_llm_simple",
    "description": "Send a message to an LLM and get a text response.",
    "parameters": ["llm_prompt"],
    "post_hint": "List of messages with roles (user or system) and contents."
},
{
    "id": "post_llm_advanced",
    "description": "Send text or image messages to an LLM for advanced responses.",
    "parameters": ["llm_prompt"],
    "post_hint": "List of advanced messages with roles, contents, and types (text or image_url)."
},
{
    "id": "async_llm_url_vision_endpoint_mlapi_basic_block_uri_image_llm_post",
    "description": "Get LLM analysis for an image via URL.",
    "parameters": ["model", "image_url", "llm_prompt"],
    "post_hint": "Body includes text related to the image URL provided."
},
{
    "id": "async_llm_block_vision_endpoint_mlapi_basic_block_image_llm_post",
    "description": "Analyze a provided image and get insights from an LLM.",
    "parameters": ["model", "llm_prompt"],
    "post_hint": "Multipart form with the image file and related text."
},
{
    "id": "async_llm_vision_endpoint_mlapi_basic_generate_image_post",
    "description": "Generate images from text or image prompts using an LLM.",
    "parameters": ["model", "response_format", "image_type", "size", "quality", "style", "num_images", "llm_prompt"],
    "post_hint": "Form with optional image or image_url and the prompt for generation."
},
{
    "id": "simple_web_search",
    "description": "Perform a simple web search for a provided term.",
    "parameters": ["search_term", "num_results", "return_results", "save_to_es", "task_id"],
    "parameter_hint": "search_term can be all google advanced search"
},
{
    "id": "simple_web_search_and_crawl",
    "description": "Perform a search and crawl operation based on a search term.",
    "parameters": ["search_term", "scrape_depth", "num_results", "max_urls", "return_results", "save_to_es", "text_only", "task_id"]
},
{
    "id": "get_web_page_text",
    "description": "Extract the text from a web page url",
    "parameters": ["url", "return_results", "save_to_es", "task_id"],
    "parameter_hint": "search_term can be all google advanced search"
},
{
    "id": "get-web-page-and-linked-pages-text",
    "description": "Extract the text from a web page URL and linked page URLs",
    "parameters": ["url", "scrape_depth", "max_urls_per_page_to_scrape", "return_results", "save_to_es", "task_id"],
    "parameter_hint": "scrape_depth of 2 means get current URL and URL links - 1 means just get URL text."
},
{
    "id": "get_web_search_crawl_results",
    "description": "Retrieve search or crawl results by task ID.",
    "parameters": ["task_id", "search_result_id", "max"]
},
{
    "id": "delete_web_search_crawl_results",
    "description": "Delete search or crawl results for a given task ID.",
    "parameters": ["task_id"]
},
{
    "id": "list_tasks_with_web_search_crawl_results",
    "description": "List unique task IDs related to the user."
},
{
    "id": "save_task_state",
    "description": "Save a variable state for a task with optional TTL.",
    "parameters": ["task_id", "var_name"],
    "post_hint": "Value to save and optional TTL."
},
{
    "id": "get_task_state",
    "description": "Retrieve saved state for a given task variable.",
    "parameters": ["task_id", "var_name"]
},
{
    "id": "delete_task_state",
    "description": "Delete saved state of a given task variable.",
    "parameters": ["task_id", "var_name"]
},
{
    "id": "list_task_state_variables",
    "description": "List all task variables with saved states.",
    "parameters": ["task_id"]
},
{
    "id": "list_tasks_with_state",
    "description": "List all tasks with saved states for the user."
},
{
    "id": "delete_all_task_state_data",
    "description": "Clear all saved state data for a task.",
    "parameters": ["task_id"]
},
{
    "id": "save_task_data",
    "description": "Save data for a task variable with optional TTL. ",
    "parameters": ["task_id", "var_name"],
    "post_hint": "Data to save and optional TTL."
},
{
    "id": "get_task_data",
    "description": "Retrieve saved data for a specified task variable. ",
    "parameters": ["task_id", "var_name"]
},
{
    "id": "get_user_data",
    "description": "Request and retrieve data from a user required to complete a task. ",
    "parameters": ["task_id", "var_name", "user_description_of_data_to_upload"]
},
{
    "id": "save_user_data",
    "description": "Save data for a user.  This is likely a results the user has requested for the assignment",
    "parameters": ["task_id", "var_name", "user_description_of_result"],
},
{
    "id": "get_task_data",
    "description": "Retrieve saved data for a specified task variable. ",
    "parameters": ["task_id", "var_name"]
},
{
    "id": "delete_task_data",
    "description": "Delete saved data for a specified task variable.",
    "parameters": ["task_id", "var_name"]
},
{
    "id": "list_task_data_variables",
    "description": "List variables with saved data for a task.",
    "parameters": ["task_id"]
},
{
    "id": "list_tasks_with_data",
    "description": "List all tasks for which the user has saved data."
},
{
    "id": "delete_all_task_data",
    "description": "Delete all saved data for a specified task.",
    "parameters": ["task_id"]
},
{
    "id": "step_executor_mlapi_basic_task_step_executor_get",
    "description": "Execute a step in task processing."
},
{
    "id": "python_executor_mlapi_basic_task_python_executor_get",
    "description": "Execute a Python main function for task processing which can access elastic results and task data.",
    "parameters": ["task_id", "main_function"]
}
]`;

export default taskCatalogSummary;
