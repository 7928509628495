import React, { useState, useEffect } from 'react';
import { Switcher, Login, Logout, UserAvatar } from '@carbon/react/icons';
import { useNavigate } from 'react-router-dom';
import {
  Header,
  HeaderContainer,
  HeaderName,
  HeaderMenuButton,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent,
  SideNav
} from '@carbon/react';
import { Link } from 'react-router-dom';

const DLTHeader = ({ isLoggedIn, user, email, onLogout }) => {
  const username = isLoggedIn && email ? email.split('@')[0].slice(0, 14) : null;
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 670);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 670);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

return (
  <HeaderContainer
    render={({ isSideNavExpanded, onClickSideNavExpand }) => (
      <Header aria-label="Carbon Tutorial">
        <SkipToContent />
        <HeaderMenuButton
          aria-label="Open menu"
          onClick={onClickSideNavExpand}
          isActive={isSideNavExpanded}
        />
        <HeaderName as={Link} to="/" prefix="">
          {isSmallScreen ? "FFAI" : "Fresh Focus AI"}
        </HeaderName>
        <SideNav
          aria-label="Side navigation"
          expanded={isSideNavExpanded}
          isPersistent={false}>
        </SideNav>
        <HeaderGlobalBar>

          {isLoggedIn ? (
        <>
          <HeaderGlobalAction id="username-display" aria-label="User" tooltipAlignment="center">
            <UserAvatar size={20}/> &nbsp; {username}
          </HeaderGlobalAction>
          <HeaderGlobalAction id="logout-link" aria-label="Log out" tooltipAlignment="center" onClick={onLogout}>
            <Logout size={20}/> &nbsp;Log out
          </HeaderGlobalAction>
        </>
      ) : (
        <>
        <HeaderGlobalAction id="login-link" aria-label="Log in" tooltipAlignment="center" onClick={handleLoginClick}>
        <Login size={20} /> &nbsp;Log in
        </HeaderGlobalAction>
        </>
      )}
          <HeaderGlobalAction aria-label="App Switcher" tooltipAlignment="end">
            <Switcher size={20} />
          </HeaderGlobalAction>
        </HeaderGlobalBar>
      </Header>
    )}
  />
);
            };

export default DLTHeader;
