import React, { Component } from 'react';
import SocialButton from './SocialButton';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  TextInput,
  Grid,
  Column,
  Link,
} from '@carbon/react';
import { GoogleLogin } from '@react-oauth/google';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AmazonLoginButton } from 'react-social-login-buttons';
import LogoImage from './../../main/logos/fresh-focus-ai-high-resolution-color-logo.png';

function decodeJwtResponse(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      isClosedBeta: true,
      // Add other state variables here if needed
    };
    this.amazonLoginRef = React.createRef();
    this.serviceheaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    };
    this.servicecredtype = 'include';

    // Set the log level
    this.log = {
      ASSERT: 1,
      ERROR: 2,
      WARN: 3,
      INFO: 4,
      DEBUG: 5,
      TRACE: 6,
      set level(level) {
        if (level >= this.ASSERT) this.a = console.assert.bind(window.console);
        else this.a = function() {};
        if (level >= this.ERROR) this.e = console.error.bind(window.console);
        else this.e = function() {};
        if (level >= this.WARN) this.w = console.warn.bind(window.console);
        else this.w = function() {};
        if (level >= this.INFO) this.i = console.info.bind(window.console);
        else this.i = function() {};
        if (level >= this.DEBUG) this.d = console.debug.bind(window.console);
        else this.d = function() {};
        if (level >= this.TRACE) this.t = console.log.bind(window.console);
        else this.t = function() {};
        this.loggingLevel = level;
      },
      get level() {
        return this.loggingLevel;
      },
    };
    this.log.level = this.log.DEBUG;
  }

  handleSocialAmazonLogin = user => {
    this.log.i('Login: Social Amazon Login');
    this.log.i(user);
    if (user._profile.email) {
      var aLogin = user._profile.email;
      var aName = user._profile.name;
      var aFirst = user._profile.firstName;
      var aLast = user._profile.lastName;
      var aToken = user._token.accessToken;
      this.log.t(
        'Login: Social Amazon successful social auth for name: ' +
          aName +
          ' user: ' +
          aLogin
      );

      //Start check closed beta
      var loginURL = '/api/auth/login';
      var heads = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Cache: 'no-cache',
      };
      var servicecredtype = 'include'; //same-origin, omit, include
      var fetchMethod = 'POST';
      var loginPostBody = {
        id: aLogin,
        email: aLogin,
        authType: 'amazon',
        password: aToken,
      };

      if (this.state.isClosedBeta) {
        var th = this;
        fetch(loginURL, {
          method: fetchMethod,
          headers: heads,
          body: JSON.stringify(loginPostBody),
          credentials: servicecredtype,
        })
          .then(response => {
            // Parse JSON and pass both the status and the JSON data to the next block
            return response.json().then(json => {
              return { status: response.status, json };
            });
          })
          .then(({ status, json }) => {
            // Destructuring to get status and json
            this.log.t('response rc');
            this.log.t(status);
            if (status === 200) {
              this.log.t('Amazon Login to FF successful');
              this.log.t('Login: calling login callback for amazon user');
              this.log.i('amazonLogin: Found auth successful');
              this.log.i('response json');
              this.log.i(json);

              var token = json.data.tokens.access_token;
              this.log.i('amazonLogin: Calling props setToken');
              this.props.setToken(token, aLogin, aLogin);
            } else if (status === 401) {
              this.log.t('Login: User not permitted');
              var errmessage = json.message; // Assuming the error message is in json.message
              toast.error(errmessage, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
              });
            } else {
              // Handle other statuses or errors
              this.log.w('Unexpected response status: ' + status);
            }
          })
          .catch(error => {
            this.log.w(`Exception in Amazon login ${error}`);
            return [];
          });
      } else {
        this.log.t(
          'Login: calling login callback for amazon user (not closed beta)'
        );
        th.props.logincallback(
          aLogin,
          aLogin,
          'amazon',
          aToken,
          aFirst,
          aLast,
          true,
          '',
          '',
          '',
          ''
        );

        th.setState({
          loginType: 'amazon',
          unAuthLoginType: 'amazon',
          showloginerrormessage: false,
          loginerrormessage: '',
        });
      }
    }
    //End check closed beta
  };

  handleSocialLogout = () => {
    this.log.t('Login: Social logout B');
    //this.refs.amazonLogin.triggerLogout();
    this.amazonLoginRef.amazonLogin.triggerLogout();
  };

  handleSocialAmazonLoginFailure = err => {
    this.log.i('Login: Social Login Failure for Amazon');
    console.error(err);
  };

  loginWithUsernameAndPassword = async (username, password) => {
    const loginURL = '/api/auth/login'; // Update with your API endpoint
    const loginPostBody = {
      id: username,
      email: username,
      authType: 'native',
      password: password,
    };

    try {
      const response = await fetch(loginURL, {
        method: 'POST',
        headers: this.serviceheaders,
        body: JSON.stringify(loginPostBody),
        credentials: this.servicecredtype,
      });

      if (response.ok) {
        const data = await response.json();
        return data.data.tokens.access_token; // Adjust according to your API response structure
      } else {
        throw new Error('Login failed');
      }
    } catch (error) {
      this.log.w(`Exception in native login ${error}`);
      return null;
    }
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { username, password } = this.state;
    const token = await this.loginWithUsernameAndPassword(username, password);

    if (token) {
      this.props.setToken(token, username, username);
      // Handle successful login (e.g., redirect to a dashboard)
    } else {
      // Handle login failure (e.g., show an error message)
      toast.error('Login failed. Please check your credentials.', {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Slide,
      });
    }
  };

  render() {
    const { username, password } = this.state;

    return (
      <div className="login-page">
        <Grid fullWidth>
          <Column lg={16} md={8} sm={4} className="login-page__banner">
            <Breadcrumb noTrailingSlash aria-label="Page navigation">
              <BreadcrumbItem>
                <a href="/login">Login</a>
              </BreadcrumbItem>
            </Breadcrumb>
            <h1 className="login-page__heading">Login to Fresh Focus AI</h1>
            <p>
              Don't have an account? <Link>Create an account</Link>
            </p>
          </Column>
          <Column md={10} lg={8} sm={4} className="login-page-content">
            <div className="login-wrapper">
              <form onSubmit={this.handleSubmit}>
                <TextInput
                  id="username"
                  labelText=""
                  className="login-input"
                  placeholder="Username"
                  value={username}
                  onChange={e => this.setState({ username: e.target.value })}
                />
                <TextInput
                  id="password"
                  labelText=""
                  className="login-input"
                  placeholder="Password"
                  type="password"
                  value={password}
                  onChange={e => this.setState({ password: e.target.value })}
                />
                <Button type="submit">Login</Button>
              </form>
            </div>
            <div className="divider-wrapper">
              <h4>OR Login with:</h4>
            </div>
            <div className="social-style-login">
              <div className="button-container">
                <GoogleLogin
                  width="258"
                  onSuccess={credentialResponse => {
                    this.log.i('Google Login success.');
                    this.log.i(credentialResponse);
                    this.log.i('Credential is: ');
                    this.log.i(credentialResponse.credential);
                    var credObj = decodeJwtResponse(
                      credentialResponse.credential
                    );
                    this.log.i('Decoded credential:');
                    this.log.i(credObj);

                    if (credObj && credObj.email) {
                      var loginURL = '/api/auth/login';
                      var gLogin = credObj.email;
                      var gToken = credentialResponse.credential;
                      var gName = credObj.name;
                      //var gFirst = credentialResponse.profileObj.givenName;
                      //var gLast = credentialResponse.profileObj.familyName;
                      this.log.i(
                        `Successful google auth for ${gLogin} name: ${gName}`
                      );

                      var heads = {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Cache: 'no-cache',
                      };
                      var servicecredtype = 'include'; //same-origin, omit, include
                      var fetchMethod = 'POST';
                      var loginPostBody = {
                        id: gName,
                        email: gLogin,
                        authType: 'google',
                        password: gToken,
                      };

                      fetch(loginURL, {
                        method: fetchMethod,
                        headers: heads,
                        body: JSON.stringify(loginPostBody),
                        credentials: servicecredtype,
                      })
                        .then(response => {
                          this.log.t(
                            '/api/auth/login for google login: response branch'
                          );
                          if (response && response.status === 200) {
                            this.log.t(
                              `Response from google auth attempt: ${
                                response.status
                              }`
                            );
                          }
                          const statusCode = response.status;
                          return response
                            .json()
                            .then(result => ({ result, statusCode }));
                        })
                        .catch(error => {
                          this.log.t(`googleLogin: Fetch error ${error}`);
                          return { result: [], statusCode: null };
                        })
                        .then(({ result, statusCode }) => {
                          this.log.i(
                            'googleLogin: login call complete, result:'
                          );
                          this.log.i(result);
                          this.log.i('googleLogin: status');
                          this.log.i(statusCode);
                          if (
                            result &&
                            statusCode === 200 &&
                            result.message.startsWith(
                              'Authentication successful'
                            )
                          ) {
                            this.log.i('googleLogin: Found auth successful');
                            var token = result.data.tokens.access_token; // ['data']['tokens']['access_token'];
                            this.log.i('googleLogin: Calling props setToken');
                            this.props.setToken(token, gLogin, gLogin);
                          } else {
                            this.log.i('googleLogin: Auth not successful');
                            this.log.i(
                              `Return code from auth is ${result.status}`
                            );
                            if (result && result.message) {
                              this.log.i(
                                `googleLogin: Auth message: ${result.message}`
                              );
                            } else {
                              this.log.i(
                                'googleLogin: no message Authentication successful, result:'
                              );
                              this.log.i(result);
                            }
                          }
                        });
                    }
                  }}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                  useOneTap
                />
              </div>
              <SocialButton
                ref={this.amazonLoginRef}
                className="social-style-login-button"
                provider="amazon"
                appId="amzn1.application-oa2-client.c54d0dbbaa0d4175b80f84475f62f91e"
                onLoginSuccess={this.handleSocialAmazonLogin}
                onLoginFailure={this.handleSocialAmazonLoginFailure}>
                <AmazonLoginButton
                  style={{ height: '38px', width: '258px' }}
                  preventActiveStyles={true}
                />
              </SocialButton>
            </div>
          </Column>
          <Column
            lg={6}
            className="login-page__logo"
            display={{ base: 'none', lg: 'block' }}>
            <img src={LogoImage} alt="Fresh Focus AI Logo" />
          </Column>
        </Grid>
        <ToastContainer />
      </div>
    );
  }
}

export default LoginPage;
